<template>
  <div>
    <b-overlay :show="loading">
      <b-card>
        <app-collapse>
          <app-collapse-item title="Búsqueda">
            <b-row>
              <b-col cols="4">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Holding
                  </h5>
                  <autocomplete-infinity
                    id="holding"
                    v-model="filter.holding"
                    url="entidades/holding"
                    placeholder="Seleccione Holding"
                    label="nombre_holding"
                    items-per-page="10"
                    @item-selected="selectTipoHolding"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Rut Empresa
                  </h5>
                  <b-form-input
                    v-model="filter.rut_empresa"
                    placeholder="Rut"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Razón Social
                  </h5>
                  <autocomplete-infinity
                    id="razon_social_empresa"
                    v-model="filter.search_string"
                    url="empresa/listado-razon-social-empresa"
                    placeholder="Seleccione Razon Social"
                    label="razon_social_empresa"
                    items-per-page="10"
                    @item-selected="selectTipoHolding"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <div class="text-right">
                  <b-button

                    variant="outline-primary"
                    type="submit"
                    @click="buscar_listado"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Buscar</span>
                  </b-button>

                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <b-card>
        <h4 class="card-title">
          Listado Empresa
        </h4>
        <b-row class="pr-1 pl-1">
          <b-col
            sm="12"
            md="6"
            class="my-1 text-right"
          >
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Mostrar</label>
              <b-form-select
                id="perPageSelect1"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
                class="mx-50 col-md-2"
                @change="onPerPage"
              />
              <label>filas por página</label>
            </div>
          </b-col>
          <b-col md="6">
            <div class="d-flex justify-content-end text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="nuevaEmpresa"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                Nuevo
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-col
          v-if="showTable"
          cols="12"
        >
          <b-table
            id="my-table"
            ref="table"
            striped
            hover
            small
            :per-page="perPage"
            :items="listado"
            :fields="filteredHeaders"
            responsive
            class="mb-0"
            show-empty
          >
            <template #empty="scope">
              <div class="text-center">
                No existen resultados
              </div>
            </template>
            <template #cell(ventas)="data">
              <div class="text-center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top.v-primary="'Listado Ventas'"
                  v-b-modal.modal-ventas
                  variant="flat-primary"
                  class="btn-icon"
                  @click="buscar_listado_ventas(data.item.id_empresa)"
                >
                  <feather-icon
                    icon="DollarSignIcon"
                  />
                </b-button>
              </div>
            </template>
            <template #cell(direccion)="data">
              <div class="text-center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top.v-primary="'Listado Direcciones'"
                  v-b-modal.modal-direcciones
                  variant="flat-primary"
                  class="btn-icon"
                  @click="buscar_listado_direccion(data.item.id_empresa)"
                >
                  <feather-icon
                    icon="BookIcon"
                  />
                </b-button>
              </div>
            </template>
            <template #cell(estado_empresa)="data">
              <h1 v-if="data.item.estado_empresa">
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="'Activo'"
                  stroke="green"
                  icon="CheckIcon"
                />
              </h1>
              <h1 v-else>
                <feather-icon
                  v-b-tooltip.hover.top.v-primary="'Inactivo'"
                  stroke="red"
                  icon="XIcon"
                />
              </h1>
            </template>
            <template #cell(contacto)="data">
              <div class="text-center">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top.v-primary="'Listado Contactos'"
                  v-b-modal.modal-contactos
                  variant="flat-primary"
                  class="btn-icon"
                  @click="buscar_listado_contacto(data.item.id_empresa)"
                >
                  <feather-icon
                    icon="UsersIcon"
                  />
                </b-button>
              </div>
            </template>
            <template #cell(acciones)="data">
              <div class="text-center">

<!--                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top.v-primary="'Detalle Empresa'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="detalleEmpresa(data.item.id_empresa)"
                >
                  <feather-icon
                    icon="FileTextIcon"
                  />
                </b-button>-->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top.v-primary="'Editar Empresa'"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="editarEmpresa(data.item)"
                >
                  <feather-icon
                    icon="Edit3Icon"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-row class="pl-1">
          <b-col
            cols="8"
            class="mt-3"
          >
            <div v-if="totalRows > 0">
              <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
            </div>
          </b-col>

          <b-col cols="4">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :value="page"
              :per-page="perPage"
              align="right"
              class="mt-3 mr-1"
              aria-controls="my-table"
              @change="onPageChange"
            />
          </b-col>

        </b-row>
      </b-card>
      <!--      MODAL VENTAS-->
      <b-modal
        id="modal-ventas"
        title="Listado Ventas"
        ok-only
        size="xl"
        ok-title="Cancelar"
      >

        <b-card>
          <b-row class="pr-1 pl-1">
            <b-col
              sm="12"
              md="6"
              class="my-1 text-right"
            >
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                  id="perPageSelect1"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="mx-50 col-md-2"
                  @change="onPerPage"
                />
                <label>filas por página</label>
              </div>
            </b-col>
          </b-row>
          <b-col
            v-if="showTable"
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="listadoVentas"
              :fields="filteredHeadersVentas"
              responsive
              class="mb-0"
              show-empty
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center primary  my-2">
                  <b-spinner class="align-middle" />
                  <strong> Cargando...</strong>
                </div>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
              <template>
                <b-spinner #cell(ventas) />
              </template>
            </b-table>
          </b-col>

          <b-row class="pl-1">
            <b-col
              cols="8"
              class="mt-3"
            >
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :value="page"
                :per-page="perPage"
                align="right"
                class="mt-3 mr-1"
                aria-controls="my-table"
                @change="onPageChange"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-modal>

      <!--      MODAL Contactos-->
      <b-modal
        id="modal-contactos"
        title="Listado Contactos"
        size="xl"
        ok-only
        ok-title="Cancelar"
        no-stacking
      >
        <b-card>
          <b-col md="12">
            <div class="d-flex justify-content-end text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-nuevo-contacto-empresa
                variant="outline-primary"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                Nuevo Contacto
              </b-button>
            </div>
          </b-col>
          <b-row class="pr-1 pl-1">
            <b-col
              sm="12"
              md="6"
              class="my-1 text-right"
            >
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                  id="perPageSelect1"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="mx-50 col-md-2"
                  @change="onPerPage"
                />
                <label>filas por página</label>
              </div>
            </b-col>
          </b-row>
          <b-col
            v-if="showTable"
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="listadoContacto"
              :fields="filteredHeadersContacto"
              responsive
              class="mb-0"
              show-empty
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center primary  my-2">
                  <b-spinner class="align-middle" />
                  <strong> Cargando...</strong>
                </div>
              </template>

              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>

              <template #cell(acciones)="data">
                <div class="text-center">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top.v-primary="'Editar Contacto'"
                    v-b-modal.modal-editar-contacto-empresa
                    variant="flat-primary"
                    class="btn-icon"
                    @click="buscar_detalle_contacto(data.item)"
                  >
                    <feather-icon
                      icon="Edit3Icon"
                    />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top.v-primary="'Eliminar Contacto'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="eliminarContactoEmpresa(data.item)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>

          <b-row class="pl-1">
            <b-col
              cols="8"
              class="mt-3"
            >
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :value="page"
                :per-page="perPage"
                align="right"
                class="mt-3 mr-1"
                aria-controls="my-table"
                @change="onPageChange"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-modal>

      <!--      MODAL Direciones-->
      <b-modal
        id="modal-direcciones"
        title="Direcciones"
        ok-only
        size="lg"
        ok-title="Cancelar"
        no-stacking
      >
        <b-card>
          <b-col md="12">
            <div class="d-flex justify-content-end text-right">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-nuevo-direccion-empresa
                variant="outline-primary"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  class="mr-50"
                />
                Nueva Dirección
              </b-button>
            </div>
          </b-col>
          <b-row class="pr-1 pl-1">
            <b-col
              sm="12"
              md="6"
              class="my-1 text-right"
            >
              <div class="d-flex align-items-center mb-1 mb-md-0">
                <label>Mostrar</label>
                <b-form-select
                  id="perPageSelect1"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="mx-50 col-md-2"
                  @change="onPerPage"
                />
                <label>filas por página</label>
              </div>
            </b-col>
          </b-row>
          <b-col
            v-if="showTable"
            cols="12"
          >
            <b-table
              id="my-table"
              ref="table"
              striped
              hover
              small
              :per-page="perPage"
              :items="listadoDireccion"
              :fields="filteredHeadersDireccion"
              responsive
              class="mb-0"
              show-empty
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center primary  my-2">
                  <b-spinner class="align-middle" />
                  <strong> Cargando...</strong>
                </div>
              </template>
              <template #empty="scope">
                <div class="text-center">
                  No existen resultados
                </div>
              </template>
              <template #cell(acciones)="data">
                <div class="text-center">

<!--                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top.v-primary="'Ver Ubicacion'"
                    v-b-modal.modal-ver-ubicacion-empresa
                    variant="flat-primary"
                    class="btn-icon"
                    @click="verDireccionMapa(data.item)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                    />
                  </b-button>-->
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top.v-primary="'Eliminar Ubicacion'"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="eliminarDireccionEmpresa(data.item)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>

          <b-row class="pl-1">
            <b-col
              cols="8"
              class="mt-3"
            >
              <div v-if="totalRows > 0">
                <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
              </div>
            </b-col>

            <b-col cols="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :value="page"
                :per-page="perPage"
                align="right"
                class="mt-3 mr-1"
                aria-controls="my-table"
                @change="onPageChange"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-modal>

      <b-modal
        v-if="modalDireccion"
        id="modal-nuevo-direccion-empresa"
        cancel-variant="outline-primary"
        ref="modal-nuevo-direccion-empresa"
        ok-title="Aceptar"
        cancel-title="Cancelar"
        centered
        size="lg"
        title="Agregar Dirección"
        @ok="direccionOk"
      >
        <form
          ref="formDireccion"
          @submit.stop.prevent="direcionSubmit"
        >
          <b-card>
            <b-row>
              <b-col cols="7">

                <b-form-group
                  label="Dirección"
                  invalid-feedback="Falta Direccion"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="name-input"
                    v-model="nuevaDireccion.direccion"
                    :state="direccion"
                    required
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Tipo Dirección"
                  label-for="h-first-name"
                  label-cols-md="4"
                  invalid-feedback="Es Requerido"

                >
                  <autocomplete-infinity
                    url="empresa/tipo-direccion"
                    placeholder="Tipo Dirección"
                    label="descripcion"
                    items-per-page="10"
                    @item-selected="selectTipoDireccion"
                    :state="tipodDireccion"
                    required



                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </form>
      </b-modal>

      <b-modal
        v-if="modalContacto"
        id="modal-nuevo-contacto-empresa"
        cancel-variant="outline-primary"
        ref="modal_nuevo_contacto_empresa"
        ok-title="Aceptar"
        cancel-title="Cancelar"
        centered
        size="lg"
        title="Agregar Contacto"
        @ok="handleOk"
      >
        <form
          ref="form"
          @submit.stop.prevent="handleSubmit"
        >
          <b-card>
            <b-row>
              <b-col cols="7">

                <b-form-group
                  :state="nombre_contacto_empresa"
                  label="Nombre"
                  invalid-feedback="Nombre es requerido"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="nuevoContacto.nombre_contacto_empresa"
                    :state="nombre_contacto_empresa"
                    required
                    type="text"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Tipo Contacto"
                  label-cols-md="4"
                >
                  <autocomplete-infinity
                    url="empresa/tipo-contacto-empresa"
                    placeholder="Tipo Contacto"
                    label="descripcion"
                    items-per-page="10"
                    @item-selected="selectTipoContactoEmpresa"
                  />

                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Cargo"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="tipo_contacto_empresa"
                    v-model="nuevoContacto.tipo_contacto_empresa"
                    placeholder="Tipo Cargo"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Departamento"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="departamento_contacto_empresa"
                    v-model="nuevoContacto.departamento_contacto_empresa"
                    placeholder="Ingrese Departamento"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Nivel"
                  label-cols-md="4"
                >
                  <autocomplete-infinity
                    url="empresa/tipo-nivel-contacto-empresa"
                    placeholder="Tipo Nivel"
                    label="DESC_NIVEL_CONTACTO"
                    items-per-page="10"
                    @item-selected="selectTipoNivelContactoEmpresa"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Correo"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="nuevoContacto.correo_contacto_empresa"
                    placeholder="Ingrese Correo"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group
                  label="Teléfono"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="telefono_contacto_empresa"
                    v-model="nuevoContacto.telefono_contacto_empresa"
                    placeholder="IngreseTeléfono"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </form>
      </b-modal>

      <b-modal
        v-if="modalContacto"
        id="modal-editar-contacto-empresa"
        cancel-variant="outline-primary"
        ok-title="Aceptar"
        cancel-title="Cancelar"
        centered
        size="lg"
        title="Editar Contacto"
        @ok="editarContactoEmpresa"
      >
        <b-card>
          <b-row>
            <b-col cols="7">
              <b-form-group
                label="Nombre"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="nombre_contacto_empresa"
                  v-model="nombre_contacto_empresa"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group
                label="Tipo Contacto"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <autocomplete-infinity
                  v-model="tipoContactoEmpresaEditar"
                  url="empresa/tipo-contacto-empresa"
                  placeholder="Tipo Contacto"
                  label="descripcion"
                  items-per-page="10"
                  :value=1
                  @item-selected="selectTipoContactoEmpresaEditar"
                />
              </b-form-group>
            </b-col>

            <b-col cols="7">
              <b-form-group
                  label="Cargo"
                  label-for="h-first-name"
                  label-cols-md="4"
              >
                <b-form-input
                    id="rut"
                    v-model="cargo_contacto_empresa"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group
                label="Departamento"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="Departamento"
                  v-model="crm_departamento_contacto"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group
                label="Nivel"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <autocomplete-infinity
                  id="DESC_NIVEL_CONTACTO"
                  v-model="TipoNivelContactoEmpresa.search_string"
                  url="empresa/tipo-nivel-contacto-empresa"
                  placeholder="Tipo Nivel"
                  label="DESC_NIVEL_CONTACTO"
                  items-per-page="10"
                  @item-selected="selectTipoNivelContactoEmpresa"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group
                label="Correo"
                label-for="h-first-name"
                label-cols-md="4"
              >
                <b-form-input
                  id="Correo"
                  v-model="correo_contacto"
                />
              </b-form-group>
            </b-col>
            <b-col cols="7">
              <b-form-group
                label="Teléfono"
                label-for="h-first-name"
                label-cols-md="4"
                type="number"
              >
                <b-form-input
                  id="Telefono"
                  v-model="telefono_contacto_empresa"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-modal>

      <b-modal
        id="modal-ver-ubicacion-empresa"
        title="Ubicacion Empresa"
        ok-only
        size="lg"
        ok-title="Cancelar"
      >
        <b-card>
          <template>
            <div>
              <l-map
                :zoom="zoom"
                :center="center"
              ><l-tile-layer :url="url" />
                <l-circle
                  :lat-lng="circle.center"
                  :radius="circle.radius"
                  :color="circle.color"
                />
              </l-map>
            </div>
          </template>

        </b-card>
      </b-modal>

    </b-overlay>
  </div>

</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInput,
  VBModal,
  BLink,
  BFormFile,
  BPagination,
  BSpinner,
  BModal,
  BCardCode,
  VBTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { LMap, LTileLayer, LCircle } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'Empresa',
  components: {
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInput,
    flatPickr,
    BLink,
    BFormFile,
    BPagination,
    BSpinner,
    BModal,
    LMap,
    LTileLayer,
    LCircle,
    BCardCode,
    BButton,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 8,
      center: [47.313220, -1.319482],
      circle: {
        center: [47.413220, -1.0482],
        radius: 4500,
        color: '#EA5455',
      },
      modalContacto:true,
      modalDireccion:true,
      showTable: true,
      perPage: 50,
      reportType: 1,
      currentPage: 1,
      page: 1,
      list: [],
      idEmpresa: null,
      tipo_direccion: null,
      nombre_funcion_contacto: '',
      id_empresa: 0,
      mensaje: null,
      filter: {
        holding: null,
        nombre_empresa: null,
        rut_empresa: null,
        item_per_page: 50,
        page: 1,
      },
      TipoDireccion: {
        search_string: '',
        item_per_page: 50,
        page: 1,
      },
      TipoNivelContactoEmpresa: {
        search_string: '',
        item_per_page: 50,
        page: 1,
      },
      TipoContactoEmpresa: {
        search_string: '',
        item_per_page: 50,
        page: 1,
      },
      filterDetalle: {
        id_empresa: 0,
        item_per_page: 50,
        page: 1,
      },
      filterBuscarContacto: {
        id_empresa: null,
        item_per_page: 50,
        page: 1,
      },
      detalleContacto: {
        id_contacto_empresa: null,
        item_per_page: 50,
        page: 1,
      },
      dataEmpresaDireccion: {
        nombre: null,
        file: null,
        id_rectificacion: 0,
      },
      nuevoContacto: {
        id_contacto_empresa: '',
        id_empresa: null,
        nombre_contacto_empresa: '',
        tipo_contacto_empresa: '',
        departamento_contacto_empresa: '',
        id_nivel_contacto: '',
        correo_contacto_empresa: '',
        telefono_contacto_empresa: '',
        id_tipo_contacto_empresa: '',
      },
      nuevaDireccion: {
        id_empresa: null,
        direccion: null,
        id_tipo: null,
      },
      direccion: null,
      id_tipo: null,
      tipodDireccion: null,
      correo_contacto: null,
      id_contacto_empresa: null,
      nombre_contacto_empresa: null,
      tipo_contacto_empresa: null,
      cargo_contacto_empresa: null,
      departamento_contacto_empresa: null,
      id_nivel_contacto: null,
      correo_contacto_empresa: null,
      telefono_contacto_empresa: null,
      id_direccion_empresa: null,
      id_tipo_contacto_empresa: null,
      contactoEditarSelect: null,
      tipoContactoEmpresaEditar: null,
      crm_departamento_contacto:null,
      locale: this.$i18n.locale,
      key: 'AIzaSyD6MwS9aPQtsx3SIDr7e9JyT0GdBv1vtOY',
      file: null,
      fields_ventas: [
        { key: 'num_ficha', label: 'N° de Ficha', display: true },
        { key: 'curso', label: 'Nombre del Curso', display: true },
        { key: 'categoria', label: 'Tipo de Venta', display: true },
        { key: 'fecha_inicio', label: 'Fecha de Venta', display: true },
        { key: 'valor_cobrado', label: 'Total Venta', display: true },
      ],
      fields_contacto: [
        { key: 'id_contacto_empresa', label: 'ID', display: false },
        { key: 'nombre_contacto', label: 'Nombre', display: true },
        { key: 'crm_cargo_contacto', label: 'Cargo', display: true },
        { key: 'crm_departamento_contacto', label: 'Departamento', display: true },
        { key: 'correo_contacto', label: 'Email', display: true },
        { key: 'telefono_contacto', label: 'Teléfono', display: true },
        { key: 'acciones', label: 'Acciones', display: true },
      ],
      fields_direccion: [
        { key: 'direccion', label: 'Dirección', display: true },
        { key: 'descripcion', label: 'Tipo', display: true },
        { key: 'fecha_registro', label: 'Fecha Registro', display: true },
        { key: 'acciones', label: 'Acciones', display: true },
      ],
      fields: [
        { key: 'id_empresa', label: 'ID', display: true },
        { key: 'rut_empresa', label: 'Rut', display: true },
        { key: 'razon_social_empresa', label: 'Razón Social', display: true },
        { key: 'giro_empresa', label: 'Giro', display: true },
        { key: 'nombre_holding', label: 'Holding', display: true },
        { key: 'ventas', label: 'Ventas', display: true },
        { key: 'contacto', label: 'Contacto', display: true },
        { key: 'direccion', label: 'Dirección', display: true },
        { key: 'estado_empresa', label: 'Estado', display: true },
        {
          key: 'acciones', label: 'Acciones', display: true, tdClass: 'text-center', thClass: 'text-center',
        },
      ],
      pageOptions: [50, 100, 500, 1000],
      transProps: {
        name: 'flip-list',
      },
    }
  },
  computed: {
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
    totalRows() {
      return this.$store.state.empresa.rowsTotal
    },
    to() {
      return this.$store.state.empresa.to
    },
    from() {
      return this.$store.state.empresa.from
    },
    listado() {
      return this.$store.state.empresa.listadoEmpresa
    },
    listadoVentas() {
      return this.$store.state.empresa.detalleEmpresaVenta
    },
    listadoContacto() {
      return this.$store.state.empresa.detalleEmpresaContacto
    },
    listadoDireccion() {
      return this.$store.state.empresa.detalleEmpresaDireccion
    },
    filteredHeaders() {
      return this.fields.filter(h => h.display === true)
    },
    filteredHeadersVentas() {
      return this.fields_ventas.filter(h => h.display === true)
    },
    filteredHeadersContacto() {
      return this.fields_contacto.filter(h => h.display === true)
    },
    filteredHeadersDireccion() {
      return this.fields_direccion.filter(h => h.display === true)
    },
    loading() {
      return this.$store.state.empresa.loading
    },
    isBusy() {
      return this.$store.state.empresa.isBusy
    },
    mensajeRespuestaAccion() {
      console.log('mensajeRespuestaAccion', this.$store.state.empresa.mensajeRespuesta.data)
      this.mensaje = this.$store.state.empresa.mensajeRespuesta
      console.log('MEnsaje', this.mensaje)
      this.$bvToast.toast({
        title: this.mensaje.titulo,
        variant: 'success',
        solid: true,
        message: this.mensaje.mensaje,
      })
    },

  },
  watch: {
    locale(val) {
      this.$i18n.locale = val
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    buscar_listado() {
      this.filter.page = 1
      this.getList()
    },
    buscar_listado_ventas(id_empresa) {
      this.filterDetalle.id_empresa = id_empresa
      this.$store.dispatch('empresa/getDetalleEmpresaVentas', this.filterDetalle)
    },
    buscar_listado_contacto(id_empresa) {
      this.id_empresa = id_empresa
      this.filterBuscarContacto.id_empresa = id_empresa
      this.$store.dispatch('empresa/getDetalleEmpresaContacto', this.filterBuscarContacto)
    },
    buscar_detalle_contacto(data) {
      console.log("---buscar_detalle_contacto---");
      console.log('buscar_detalle_contacto', data)
      this.id_contacto_empresa = data.id_contacto_empresa
      this.nombre_contacto_empresa = data.nombre_contacto
      this.tipo_contacto_empresa = data.id_tipo_contacto_empresa
      this.cargo_contacto_empresa = data.crm_cargo_contacto
      this.crm_departamento_contacto = data.crm_departamento_contacto
      this.correo_contacto = data.correo_contacto
      this.telefono_contacto_empresa = data.telefono_contacto
      this.id_nivel_contacto = data.id_nivel_contacto

      console.log("id_tipo_contacto_empresa",data.id_tipo_contacto_empresa);


     this.tipoContactoEmpresaEditar={descripcion: "Principal (recepción)",
      fecha_registro: "2017-05-25 10:32:01",
      id_tipo_contacto_empresa: 1};


      console.log("this.tipoContactoEmpresaEditar",this.tipoContactoEmpresaEditar);




    },
    buscar_editar_contacto(id_contacto_empresa) {
      this.$store.dispatch('empresa/editarContactoEmpresa', this.filterBuscarContacto)
    },
    buscar_listado_direccion(id_empresa) {
      this.filterDetalle.id_empresa = id_empresa
      this.nuevaDireccion.id_empresa = id_empresa
      this.$store.dispatch('empresa/getDetalleEmpresaDireccion', this.filterDetalle)
    },
    onPageChange(page) {
      this.page = page
      this.filter.page = page
      this.filter.page = page
      this.getList()
    },
    onChangePerPage() {
      this.page = 1
      this.getList()
    },
    onPerPage() {
      this.filter.rowsPerPage = this.perPage
      this.getList()
    },
    getList() {
      this.$store.dispatch('empresa/getListadoEmpresa', this.filter)
    },
    nuevaEmpresa() {
      this.$router.push({ name: 'agregarEmpresa' })
    },
    nuevaContactoEmpresa() {
      console.log('NUEVO CONTACTO', this.nuevoContacto)

      this.nuevoContacto.id_empresa = this.id_empresa
      console.log("VALIDA CORREO",this.validarCorreoOrTelefono(this.nuevoContacto.correo_contacto_empresa,this.nuevoContacto.telefono_contacto_empresa));
      if(this.validarCorreoOrTelefono(this.nuevoContacto.correo_contacto_empresa,this.nuevoContacto.telefono_contacto_empresa)){
        console.log("DENTRO EMPRESA",this.nuevoContacto);
        this.$store.dispatch('empresa/agregarEditarContactoEmpresa', this.nuevoContacto)
        this.modalContacto=false
        this.$bvToast.toast('Se agrego correctamente', {
          title: 'Agregar ',
          variant: 'success',
          solid: true,
        })
      }

    },
    editarContactoEmpresa() {
      console.log("holia");
      this.nuevoContacto.id_contacto_empresa = this.id_contacto_empresa
      this.nuevoContacto.nombre_contacto_empresa = this.nombre_contacto_empresa
      this.nuevoContacto.id_tipo_contacto_empresa = this.tipo_contacto_empresa
      this.nuevoContacto.departamento_contacto_empresa = this.crm_departamento_contacto
      this.nuevoContacto.correo_contacto_empresa = this.correo_contacto
      this.nuevoContacto.tipo_contacto_empresa = this.tipoContactoEmpresaEditar
      this.nuevoContacto.telefono_contacto_empresa = this.telefono_contacto_empresa
      this.nuevoContacto.id_empresa = this.id_empresa
      this.nuevoContacto.id_nivel_contacto = 1
      console.log('EDITAR CONTACTO', this.nuevoContacto)
      this.$store.dispatch('empresa/agregarEditarContactoEmpresa', this.nuevoContacto)
      this.modalContacto=true
      this.$bvToast.toast('Se edito correctamente', {
        title: 'Editar ',
        variant: 'success',
        solid: true,
      })

    },
    nuevaDireccionEmpresa() {
      this.$store.dispatch('empresa/agregarDireccionEmpresa', this.nuevaDireccion)
      this.modalDireccion=false
      this.$bvToast.toast('Se agrego correctamente', {
        title: 'Agregar ',
        variant: 'success',
        solid: true,
      })

    },
    editarEmpresa(data) {
      console.log('editarEmpresa', data);
      this.$store.commit('empresa/setEditarEmpresa', data)
      this.$store.commit('empresa/setIdEmpresa', data.id_empresa)
      this.$router.push({ name: 'editarEmpresa', params: { id: data.id_empresa } })
    },
    setIdEmpresa() {
      console.log('setIdEmpresa', this.id_empresa)
    },
    detalleEmpresa(id_empresa) {
      this.$store.commit('empresa/setIdEmpresa', id_empresa)
      this.$router.push({ name: 'detalleEmpresa', params: { id: id_empresa } })
    },
    selectTipoHolding(holding) {
      this.filter.holding = holding != null ? holding.id_holding : null
    },
    selectTipoDireccion(id_tipo) {
      this.nuevaDireccion.id_tipo = id_tipo.id_tipo
      this.TipoDireccion.search_string = id_tipo != null ? id_tipo.id_tipo : null
    },
    selectTipoContactoEmpresa(tipo_contacto) {
      this.nuevoContacto.id_tipo_contacto_empresa = tipo_contacto != null ? tipo_contacto.id_tipo_contacto_empresa : null
    },
    selectTipoContactoEmpresaEditar(tipo_contacto) {
      console.log("tipo_contacto",tipo_contacto);
      this.contactoEditarSelect = tipo_contacto != null ? tipo_contacto : null
    },

    selectTipoNivelContactoEmpresa(nivel_contacto) {
      this.nuevoContacto.id_nivel_contacto = nivel_contacto != null ? nivel_contacto.ID_NIVEL_CONTACTO : null
    },

    eliminarContactoEmpresa(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar a ${data.nombre_contacto}` + '?', {
          title: 'Eliminar Contacto',
          size: 'sm',
          okVariant: 'outline-primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.id_contacto_empresa = data.id_contacto_empresa
            console.log('CONTACTO ID', this.id_contacto_empresa)
            this.$store.dispatch('empresa/getEliminarContactoEmpresa', data.id_contacto_empresa)
            this.$bvModal.hide('modal-contactos')
            this.$bvToast.toast('Eliminado Correctamente', {
              title: 'Eliminado ',
              variant: 'success',
              solid: true,
            })
            this.$bvModal.hide('modal-nuevo-direccion-empresa')
          }
        })
    },
    eliminarDireccionEmpresa(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar la direccion: ${data.direccion}` + '?', {
          title: 'Eliminar Direccion',
          size: 'sm',
          okVariant: 'outline-primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.id_direccion_empresa = data.id_direccion_empresa
            this.$store.dispatch('empresa/getEliminarDireccionEmpresa', this.id_direccion_empresa)
            this.$bvModal.hide('modal-direcciones')
            this.$bvToast.toast('Eliminado Correctamente', {
              title: 'Eliminado ',
              variant: 'success',
              solid: true,
            })
          }

          this.boxTwo = value
        })
    },
    verDireccionMapa(ubicacion) {
      console.log(ubicacion.direccion)
      const datosGoogle = {
        key: this.key,
        addres: ubicacion.direccion,
      }
      this.$store.dispatch('empresa/getDireccionGoogleEmpresa', datosGoogle)
    },
    handleSubmit() {
      console.log("2");
      if (!this.checkFormValidity()) {
        console.log('Maloo')
      } else {
        this.nuevaContactoEmpresa()
        this.limpiarFormularioContacto
      }
    },
    handleOk(bvModalEvt) {
      console.log("1");
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nombre_contacto_empresa = valid

      return valid
    },
    direcionSubmit() {
      if (!this.checkDireccion()) {
        return
      } else {
        this.nuevaDireccionEmpresa()
        this.limpiarFormularioDireccion()
      }
    },
    direccionOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.direcionSubmit()
    },
    checkDireccion() {
      const valid = this.$refs.formDireccion.checkValidity()
      this.direccion = valid
      this.tipodDireccion = valid
      return valid
    },

    limpiarFormularioContacto() {
      this.nuevoContacto.id_contacto_empresa = null,
      this.nuevoContacto.id_empresa = null,
      this.nuevoContacto.nombre_contacto_empresa = '',
      this.nuevoContacto.tipo_contacto_empresa = '',
      this.nuevoContacto.departamento_contacto_empresa = '',
      this.nuevoContacto.id_nivel_contacto = '',
      this.nuevoContacto.correo_contacto_empresa = '',
      this.nuevoContacto.telefono_contacto_empresa = '',
      this.nuevoContacto.id_tipo_contacto_empresa = ''
      console.log('Limpiar contacto', this.nuevoContacto)
    },
    limpiarFormularioDireccion() {
     this.nuevaDireccion.id_empresa= '',
     this.nuevaDireccion.direccion= '',
     this.nuevaDireccion.id_tipo= '',
      console.log('Limpiar Direcc', this.nuevaDireccion)
    },
    validarCorreoOrTelefono(correo, telefono){
      console.log("validarCorre",correo);
      console.log("validarCorreoOrTelefono",telefono);
      if(correo =='' && telefono ==''){
        this.$bvToast.toast('Es requerido telefono o correo ', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return false
      }
      return true
/*      const validarCorreo=/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      if(!validarCorreo.test(correo)){
        this.$bvToast.toast('Correo no tiene el formato correcto ', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }*/
    }


  },

}

</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.vue2leaflet-map{&.leaflet-container{height: 350px;}}
.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px!important;
  color: #6e6b7b;
}
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Browse';
}

</style>
